import React from "react";
import "../styles/basepage.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

export default function HomePage(): React.JSX.Element {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r">
            <div className={"flex flex-row items-center"}>
                <img src={require("../assets/images/logi-logo.png")} alt="logo" className="w-40 h-40"/>
                <div className="flex flex-col p-5">


                    <h1 className="text-7xl font-bold text-white">Logi-IT</h1>
                    <h2 className="text-2xl font-bold text-white mt-3">
                        Gérer votre établissement n'a jamais été aussi simple.
                    </h2>
                </div>
            </div>


            <h1 className="text-4xl font-bold text-white text-center mt-10 mb-10">
                Site en maintenance
            </h1>
            <p className="text-white text-center mt-5">
               Nous contacter: <a href="mailto:contact@logi-it.com" className="underline"> contact@logi-it.com</a>
            </p>
            <p className="text-white text-center mt-5">
                Support technique: <a href="mailto:support@logi-it.com" className="underline"> support@logi-it.com </a>
            </p>
            
            <a className="flex flex-row items-center justify-center" href="https://www.linkedin.com/company/logi-it" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} size="2x" className="text-white mt-5"/>
            </a>
            
        </div>
    );
}
